<template>
  <div class="how" :class="{'detail-page':$route.name === 'faq.view'}">
    <router-view v-if="$route.name === 'faq.view'" />
    <template v-else>
      <v-list
        class="faq-block"
        :subheader="true"
        v-for="faq in $store.state.faq.all"
        :key="faq.id"
      >
        <v-subheader>{{ $t(faq.name) }}</v-subheader>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in faq.items"
            :key="item.id"
            :to="{ name: 'faq.view', params: { id: faq.id } }"
          >
            <v-list-item-avatar class="count">
              {{i + 1}}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'faq',
  metaInfo: {
    title: 'FAQ'
  },
  methods: {
    ...mapActions(['logout'])
  },
  created () {
    if (this.currentUser.role === 'group') {
      this.logout();
    }
  }
};
</script>
